<template>
  <div class="profileBox" v-if="userInfos">
    <div class="profileContent">
      <div class="imageBg">
        <div class="textContent" v-if="!userInfos.authCompany && !userInfos.company">
          <div class="title">Welcome To Echola!</div>
          <div class="setps">
            <div class="item">
              <div class="index">1</div>
              <div class="content">
                {{ $t("home.Improve personal information") }}
              </div>
            </div>
            <div class="line"></div>
            <div class="item">
              <div class="index">2</div>
              <div class="content">{{ $t("home.Create/Join Company") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="contentForm">
        <div class="header" v-if="businessUserId === userId">
          <template v-if="!userCompanyId">
            <el-button size="small" v-if="!isEdit" @click="isEdit = !isEdit">{{
              $t("home.Modify information")
            }}</el-button>
            <el-button size="small" @click="submit">{{
              $t("people.SavaOrNext")
            }}</el-button>
          </template>
          <template v-else>
            <el-button size="small" v-if="!isEdit" @click="isEdit = !isEdit">{{
              $t("home.Modify information")
            }}</el-button>
            <el-button size="small" v-else @click="submit">{{
              $t("home.Confirmation Information")
            }}</el-button>
          </template>
        </div>
        <div class="formBox">
          <div class="left">
            <div class="formImage">
              <el-image :src="(userInfos && userInfos.avatarUrl) || userInfos.avatarFileUrl" fit="cover" />
              <div class="mask" v-show="isEdit">
                <i class="iconfont icon-xiangji"></i>
                <input type="file" @change="confirmUploadLogo" />
              </div>
            </div>
            <div class="content">
              <div class="name">{{ userInfos.username }}</div>
              <div class="job">{{ userInfos.position }}</div>
              <div class="isBindPhoneWecat">
                <div class="item">
                  <i
                    class="iconfont icon-shouji"
                    :class="userInfos.phone ? 'isBind' : ''"
                  ></i>
                </div>
                <div class="item">
                  <i
                    class="iconfont icon-weixin"
                    :class="userInfos.authWeChat ? 'isBind' : ''"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <el-form 
              ref="profileFormRef" 
              :rules="rules" 
              :model="profileForm" 
              class="profileForm"
              :class="isEdit ? 'edit' : ''">
              <el-form-item prop="nickName">
                <div class="title">{{ $t('EditMine.Name') }}</div>
                <el-input v-if="isEdit" v-model="profileForm.nickName" autocomplete="off" />
                <div v-else class="text">{{ profileForm.nickName }}</div>
              </el-form-item>
              <el-form-item prop="position">
                <div class="title">{{ $t('EditMine.Title') }}</div>
                <el-input v-if="isEdit" v-model="profileForm.position" autocomplete="off" />
                <div v-else class="text">{{ profileForm.position }}</div>
              </el-form-item>
              <el-form-item prop="email">
                <div class="title">{{ $t('EditMine.Email') }}</div>
                <el-input v-if="isEdit" v-model="profileForm.email" autocomplete="off">
                  <span 
                    class="bindEmail" 
                    slot="suffix"
                    @click="validatorEmail"
                  >{{ $t('edit validate') }}</span>
                </el-input>
                <div class="text" v-else>{{ userInfos.email }}</div>
              </el-form-item>
              <el-form-item prop="country">
                <div class="title">{{ $t('EditMine.Country') }}</div>
                <el-select
                  v-model="profileForm.country"
                  filterable
                  :placeholder="$t('customerInfo.Choose the country')"
                  autocomplete="off"
                  appendToBody
                  v-if="isEdit"
                >
                  <el-option
                    v-for="item in country"
                    :key="item.iso3"
                    :label="item.key"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <div v-else class="text">{{ isFindData(country, profileForm.country, 'value', 'key') }}</div>
              </el-form-item>
              <el-form-item prop="timeZone">
                <div class="title">{{ $t('EditMine.Timezone') }}</div>
                <el-select
                  v-model="profileForm.timeZone"
                  filterable
                  :placeholder="$t('customerInfo.Choose the timezone')"
                  autocomplete="off"
                  appendToBody
                  v-if="isEdit"
                >
                  <el-option
                    v-for="item in timezone"
                    :key="item.value"
                    :label="item.key"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <div v-else-if="isFindData(timezone, profileForm.timeZone, 'value', 'key')" class="text" :title="isFindData(timezone, profileForm.timeZone, 'value', 'key')">
                  {{ isFindData(timezone, profileForm.timeZone, 'value', 'key').split("(")[0] }}
                </div>
              </el-form-item>
              <el-form-item v-if="userCompanyId">
                <div class="title">{{ userId === businessUserId ? $t('EditMine.MyCompany') : $t('InformationCenter.Company') }}</div>
                <div @click="goCompanyDetail" class="aLink" v-if="userInfos.authCompany">
                  {{ userInfos.authCompany.name }}
                </div>
                <div @click="goCompanyDetail" class="aLink" v-else-if="userInfos.company">
                  {{ userInfos.company.name }}
                </div>
              </el-form-item>
              <el-form-item>
                <div class="title">{{ $t('mineInfo.PhoneWechat') }}</div>
                <div class="isBindPhoneWecat size32">
                  <div class="label">
                    <div class="item">
                      <i
                        class="iconfont icon-shouji"
                        :class="profileForm.phone ? 'isBind' : ''"
                      ></i>
                    </div>
                    <div class="bindText" @click="bindPhone">{{ profileForm.phone ? $t('bind.unBind') : $t('bind.Bind') }}</div>
                  </div>
                  <el-popover
                    placement="top"
                    :title="$t('bind.Bind')+$t('EditMine.WeChat')"
                    width="400"
                    trigger="click">
                    <iframe
                      :src="weChatUrl"
                      frameborder="0"
                      width="100%"
                      height="390px"
                      v-if="weChatUrl"
                    ></iframe>
                    <div class="label" slot="reference">
                      <div class="item">
                        <i
                          class="iconfont icon-weixin"
                          :class="profileForm.authWeChat ? 'isBind' : ''"
                        ></i>
                      </div>
                      <div class="bindText">{{ profileForm.authWeChat ? $t('bind.unBind') : $t('bind.Bind') }}</div>
                    </div>
                  </el-popover>
                </div>
              </el-form-item>
              <el-form-item>
                <div class="title">{{ $t('login.Login')+$t('login.Password') }}</div>
                <div class="isBindPhoneWecat size32">
                  <div class="label">
                    <div class="item">
                      <i class="iconfont icon-zhanghao"></i>
                    </div>
                    <div class="bindText" @click="isPasswordVisible = !isPasswordVisible">{{ $t('customerInfo.Change Password') }}</div>
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible="visiblePhone"
      :title="`${
        profileForm && profileForm.phone ? $t('bind.unBindPhone') : $t('bind.BindPhone')
      }`"
      width="300px"
      :before-close="closePhoneAuth"
      :modal="visiblePhone"
      :destroy-on-close="true"
      v-move-outside
    >
      <el-form
        :model="profilePhoneForm"
        :rules="profilePhoneRules"
        ref="profilePhoneFormRef"
      >
        <el-form-item prop="phone">
          <el-input
            v-model="profilePhoneForm.phone"
            :placeholder="$t('login.phoneNo')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="profilePhoneForm.code" :placeholder="$t('login.authCode')">
            <template #suffix>
              <span @click="getPhoneAuthCode()" style="cursor: pointer">{{
                countDown > 0 ? countDown + 's' : $t('bind.getCode')
              }}</span>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
          class="cancel_button"
          @click="closePhoneAuth"
          size="small"
          >{{ $t('Forwarder.Cancel') }}
        </el-button>
        <el-button
          type="primary"
          @click="phoneAuth()"
          size="small"
          >{{ $t('bind.confirmBinding') }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      :visible="isPasswordVisible"
      :title="$t('customerInfo.Change Password')"
      width="358px"
      :before-close="closePasswordAuth"
      :modal="isPasswordVisible"
      :destroy-on-close="true"
      v-move-outside
    >
      <el-form
        :model="profilePasswordForm"
        :rules="profilePasswordRules"
        ref="profilePasswordFormRef"
      >
        <el-form-item prop="password">
          <el-input
            v-model.trim="profilePasswordForm.password"
            type="password"
            autocomplete="off"
            :placeholder="$t('customerInfo.Original password')"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item prop="nPassword">
          <el-input
            v-model.trim="profilePasswordForm.nPassword"
            type="password"
            autocomplete="off"
            :placeholder="$t('customerInfo.New password')"
            show-password
          >
          </el-input>
          <PasswordStrength
            :password="profilePasswordForm.nPassword"
          ></PasswordStrength>
        </el-form-item>
        <el-form-item prop="cPassword">
          <el-input
            v-model.trim="profilePasswordForm.cPassword"
            type="password"
            autocomplete="off"
            :placeholder="$t('customerInfo.Confirm password')"
            show-password
          >
          </el-input>
          <PasswordStrength
            :password="profilePasswordForm.cPassword"
          ></PasswordStrength>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button
          class="cancel_button"
          @click="closePasswordAuth"
          size="small"
          >{{ $t('Forwarder.Cancel') }}
        </el-button>
        <el-button
          type="primary"
          @click="passwordHandler()"
          size="small"
          >{{ $t('customerInfo.cm') }}
        </el-button>
      </div>
    </el-dialog>
    <cropper
      :visible="isCropperVisible"
      :url="cropperImage"
      :fixed="true"
      @close="isCropperVisible = false"
      @confirm="pictureHandler"
    />
    <companySelectDialog ref="companySelectDialog" />
    <validatorEmailDialog ref="validatorEmailDialog" :code.sync="profileForm.code" />
    <audioDialog />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  wechatLogin,
  unbindWechat,
  bindWechat,
  unbindPhone,
  bindPhone,
  getPhoneCode,
  updateProfile,
  updatePassword
} from '@/api';
import { getCompanyList, getOtherInfoData } from "@/api/customer";
import { uploadFile } from '@/api/files';
import { isFindData } from "@/utils/utils.js";

import companySelectDialog from "@/components/companySelectDialog";
import validatorEmailDialog from "@/components/validatorEmailDialog";
import audioDialog from "@/components/audioDialog";

export default {
  name: 'profile',
  components: {
    PasswordStrength: () => import('@/components/passwordStrength/passwordStrength.vue'),
    cropper: () => import('@/components/cropper/cropper.vue'),
    companySelectDialog,
    validatorEmailDialog,
    audioDialog
  },
  data() {
    return {
      isFindData: isFindData,
      userInfos: null,
      isEdit: false,
      visiblePhone: false,
      isCropperVisible: false,
      isPasswordVisible: false,
      cropperImage: "",
      weChatUrl: "",
      userCompanyName: "",
      userId: "",
      profileForm: {
        code: "",
        phone: '',
        avatarFileId: "",
        name: "",
        title: "",
        email: "",
        country: "",
        timeZone: "",
        userCompanyId: ""
      },
      rules: {
        nickName: [
          { required: true, message: this.$t('customerInfo.Please input name'), trigger: 'blur' },
          {
            min: 2,
            max: 64,
            message: this.$t('customerInfo.Length should be 2 to 64'),
            trigger: 'blur',
          },
        ],
        email: [
          { required: true, message: this.$t('registerLoging.PleaseinputaEmail'), trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if(value == this.userInfos?.email) {
                callback();
              } else {
                if(!this.profileForm.code) {
                  callback(
                    new Error(
                      this.$t('Please verify the email first')
                    )
                  );
                } else {
                  callback();
                }
              }
            },
            trigger: 'blur'
          }
        ],
        position: [
          { required: true, message: this.$t('customerInfo.Please input title'), trigger: 'blur' },
          {
            min: 2,
            max: 64,
            message: this.$t('customerInfo.Length should be 2 to 64'),
            trigger: 'blur',
          },
        ],
        country: [
          { required: true, message: this.$t('customerInfo.Please select country'), trigger: 'change' },
        ],
        timeZone: [
          {
            required: true,
            message: this.$t('customerInfo.Please select timezone'),
            trigger: 'change',
          },
        ]
      },
      profilePhoneForm: {
        phone: "",
        code: ""
      },
      profilePhoneRules: {
        phone: [
          { required: true, message: this.$t('customerInfo.Please input phone'), trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: window.vm.$t('customerInfo.Please input correct phone'),
            trigger: 'blur',
          },
        ],
        code: [{ required: true, message: this.$t('customerInfo.Please input code'), trigger: 'blur' }],
      },
      countDown: 0,
      companyList: [],
      profilePasswordForm: {
        password: '',
        nPassword: '',
        cPassword: '',
      },
      profilePasswordRules: {
        password: [
          {
            required: true,
            message: this.$t('customerInfo.Please input password'), 
            trigger: 'blur',
          },
        ],
        nPassword: [
          {
            validator: (rule, value, callback) => {
              if (value.length < 8) {
                callback(new Error(this.$t('customerInfo.Password must be at least 8 characters')));
              } else if (!/[A-Za-z]/.test(value)) {
                callback(
                  new Error(
                    this.$t('customerInfo.Password must contain at least one letter and one number')
                  )
                );
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        cPassword: [
          {
            validator: (rule, value, callback) => {
              if (value.length < 8) {
                callback(new Error(this.$t('customerInfo.Password must be at least 8 characters')));
              } else if (value !== this.profilePasswordForm.nPassword) {
                callback(new Error(this.$t('customerInfo.Two passwords are inconsistent!')));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
      }
    }
  },
  computed: {
    ...mapGetters(['country', 'timezone', 'userInfo', 'businessUserId']),
    isUserPerfect() {
      return this.userInfos.nickName && this.userInfos.position && this.userInfos.country && this.userInfos.timeZone && (this.userInfos.avatarUrl || this.userInfos.avatarFileUrl);
    }
  },
  mounted() {
    this.userId = this.$route.query.businessUserId;
    this.getWecatCode();
  },
  activated() {
    this.userId = this.$route.query.businessUserId;
    this.getUserInfoData();
  },
  watch: {
    $route(to) {
      if(to.query.code) {
        this.weChatHandler(to.query.code)
      }
    },
    'profileForm.code'() {
      if(this.$refs.profileFormRef) {
        this.$refs.profileFormRef.clearValidate(['email']);
      }
    }
  },
  methods: {
    validatorEmail() {
      this.$refs.validatorEmailDialog.open(this.profileForm.email);
    },
    querySearchAsync(queryString, cb) {
      if(!queryString) {
        cb([]);
        return;
      }
      getCompanyList({
        name: queryString,
      }).then((response) => {
        cb(response.list);
      }).catch(() => {
        cb([]);
      });
    },
    getUserInfoData() {
      if(this.userId === this.businessUserId) {
        this.$store.dispatch("user/getInfo").then(() => {
          this.userInfos = this.userInfo;
          this.userCompanyId = this.userInfo.authCompany?.companyId || '';
          if(!this.userCompanyId) {
            this.isEdit = true;
          }
          this.profileForm = {
            ...this.userInfo,
            userCompanyId: this.userInfo.authCompany?.companyId || ''
          };
        })
      } else {
        getOtherInfoData(this.userId).then(data => {
          this.userInfos = data;
          this.userCompanyId = data.company?.companyId || '';
          this.profileForm = {
            ...data,
            userCompanyId: data.company?.companyId || ''
          };
        })
      }
    },
    closePasswordAuth() {
      this.isPasswordVisible = false;
      this.$refs.profilePasswordFormRef.resetFields();
    },
    getWecatCode() {
      const { origin, pathname } = window.location;
      wechatLogin({
        callBackUrl: `${origin}${pathname}#/profile?businessUserId=${this.$route.query.businessUserId}`,
      }).then((data) => {
        this.weChatUrl = data;
      })
    },
    weChatHandler(value) {
      if (this.profileForm.authWeChat) {
        unbindWechat({ code: value }).then(() => {
          this.getUserInfoData();
        });
      } else {
        bindWechat({ code: value }).then(() => {
          this.getUserInfoData();
        });
      }
      this.isEdit = false;
    },
    bindPhone() {
      this.visiblePhone = true;
      this.profilePhoneForm.phone = this.profileForm.phone;
    },
    closePhoneAuth() {
      this.visiblePhone = false;
      this.$refs.profilePhoneFormRef.resetFields();
      this.countDown = 0;
    },
    async getPhoneAuthCode() {
      if (this.countDown > 0) return;
      if (
        !this.profilePhoneForm.phone &&
        !/^1[3456789]\d{9}$/.test(this.profilePhoneForm.phone)
      ) {
        this.$message.error(this.$t('customerInfo.Please input phone'));
        return;
      }
      try {
        getPhoneCode({ phone: this.profilePhoneForm.phone }).then(data => {
          this.countDown = 60;
          const timer = setInterval(() => {
            this.countDown--;
            if (this.countDown <= 0) {
              clearInterval(timer);
            }
          }, 1000);
        });
      } catch (error) {
        console.log(error);
      }
    },
    phoneAuth() {
      this.$refs.profilePhoneFormRef.validate(async (valid) => {
        if(!valid) return;
        let api = this.profileForm.phone ? unbindPhone : bindPhone;
        api({
          phone: this.profilePhoneForm.phone,
          smsCode: this.profilePhoneForm.code,
        }).then(() => {
          this.$message.success(this.$t(`bind.${this.profileForm.phone ? 'unBindSuccess' : 'bindSuccess'}`));
          this.getUserInfoData();
          this.closePhoneAuth();
          this.profilePhoneForm.phone = '';
        });
      });
    },
    async passwordHandler() {
      this.$refs.profilePasswordFormRef.validate(async (valid) => {
        if (valid) {
          try {
            const { data } = await updatePassword({
              oldPasswd: this.profilePasswordForm.password,
              newPasswd: this.profilePasswordForm.nPassword,
            });
            this.$message.success(this.$t('customerInfo.Password Update Successful'));
            this.closePasswordAuth();
            await this.$store.dispatch('user/logout');
            this.$router.replace({
              path: `/login?username=${this.profileForm?.username}`,
            });
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    beforeAvatarUpload({ size, type }) {
      const isJPG =
          type === 'image/jpeg' ||
          type === 'image/png' ||
          type === 'image/jpg' ||
          type === 'image/gif';
      const isLt2M = size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error(window.vm.$t('customerInfo.Picture must be JPG or PNG or GIF!'));
      }
      if (!isLt2M) {
        this.$message.error(window.vm.$t('customerInfo.Picture size can not exceed 2MB!'));
      }
      return isJPG && isLt2M;
    },
    confirmUploadLogo(event) {
      let files = event.target.files[0]
      if (!this.beforeAvatarUpload(files)) return;
      this.cropperImage = URL.createObjectURL(files);
      this.isCropperVisible = true;
      event.target.value = "";
    },
    pictureHandler(file) {
      //只传一个文件的情况
      var data_up = new FormData();
      data_up.append('files', file);
      data_up.append('configCode', 'userImage');
      uploadFile(data_up)
        .then((data) => {
          if (!data[0]) return;
          this.profileForm.avatarFileId = data[0];
          this.$nextTick(() => {
            this.$set(this.userInfos, 'avatarUrl', URL.createObjectURL(file))
          })
          this.$message.success(this.$t('customerInfo.Upload success'));
          this.isCropperVisible = false;
        })
        .catch((error) => {});
    },
    goCompanyDetail() {
      if(this.userInfos.authCompany) {
        this.$router.push(`/companyProfile?companyName=yourownpage&companyId=${this.userInfos.authCompany.companyId}&type=edit`)
      } else if(this.userInfos.company) {
        this.$router.push(`/companyProfile?companyId=${this.userInfos.company.companyId}&type=see`)
      } else {
        this.$router.push(`/companyProfile?companyName=yourownpage&type=add`)
      }
    },
    submit() {
      if(!this.profileForm.avatarFileId) {
        this.$message.warning(this.$t("home.PleaseuploadCustomerlogo"));
        return;
      }
      this.$refs.profileFormRef.validate(async (valid) => {
        if (valid) {
          updateProfile({
            nickName: this.profileForm.nickName,
            position: this.profileForm.position,
            timeZone: this.profileForm.timeZone,
            country: this.profileForm.country,
            avatarFileId: this.profileForm.avatarFileId,
            emailCode: this.profileForm.code,
            email: this.profileForm.email
          }).then(() => {
            this.getUserInfoData();
            this.isEdit = false;
            if(!this.userCompanyId) {
              this.$refs.companySelectDialog.open();
            } else {
              this.$message.success(this.$t('customerInfo.Update Successful'));
            }
          });
        }
      });
    }
  },
}
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
